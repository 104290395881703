import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { ROUTE_CONSTANTS } from "src/constants";
import { Account, TransferHistoryItem } from "src/services/generatedApi";
import ico_send from "src/assets/ico_send.svg";
import AccContext from "src/stores/AccountStore";
import TransferContext from "src/stores/TransferStore";
import NotificationContext from "src/stores/NotificationStore";
import ButtonBack from "./ButtonBack";
import { stddatetime } from "src/utils";
import SendSms, { clickSendSms, canSendSMS } from "./SendSms";
import GlobalTheme from "src/theme";

const { DEMO_LOGIN_PATH, MAIN_PAGE_PATH, NP_EVENT_DEMO } = ROUTE_CONSTANTS;

interface IURLParams {
  id?: string;
}

const TransferSuccessful = observer(({ appStyles, fromDemoPage }: { appStyles: any; fromDemoPage: boolean }) => {
  const accStore = useContext(AccContext);
  const transferStore = useContext(TransferContext);
  const notificationStore = useContext(NotificationContext);
  const [account, setAccount] = useState<Account | undefined>(undefined);
  const [transfer, setTransfer] = useState<TransferHistoryItem | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { fi_ui_customization } = GlobalTheme();

  const urlParams: IURLParams = useParams();

  useEffect(() => {
    const loadStores = async () => {
      setIsLoading(true);
      try {
        await accStore.loadAccounts();
        await transferStore.loadTransfers();
      } catch (error) {
        navigate(DEMO_LOGIN_PATH);
      } finally {
        setIsLoading(false);
        const t1 = transferStore.history.find(({ id }) => id === urlParams.id);
        setTransfer(t1);
        if (t1) {
          setAccount(accStore.accounts.find(({ id }) => id === t1.account));
        }
      }
    };
    loadStores();
    return () => {
      notificationStore.resetPhoneTransactions();
    };
  }, [urlParams.id, accStore, transferStore, navigate, notificationStore]);

  const transfer_notification = notificationStore.notifications.find((one) => one.data.transfer_id === urlParams.id);

  const shouldShowSmsSend = () => {
    if (transfer_notification != undefined && canSendSMS) {
      return (
        transfer?.recipient.type === "phone" &&
        (transfer_notification.verb == "shortlink_generated" || fi_ui_customization?.send_on_network_notification)
      );
    }
    return false;
  };

  let resendData = {
    identifier: transfer_notification?.data?.identifier,
    children: "",
    disabled: isLoading,
    message: `${fi_ui_customization.sms_customization.on_net_first} $${transfer_notification?.data?.amount?.value} ${fi_ui_customization.sms_customization.on_net_second} "${transfer_notification?.data?.note}". ${fi_ui_customization.sms_customization.on_net_third}`,
  };

  if (transfer_notification?.verb == "shortlink_generated") {
    resendData = {
      ...resendData,
      message: `${fi_ui_customization.sms_customization.off_net_first} $${transfer_notification?.data?.amount?.value} ${fi_ui_customization.sms_customization.off_net_second} ${transfer_notification?.data?.financial_institution} ${fi_ui_customization.sms_customization.off_net_third} "${transfer_notification?.data?.note}". ${fi_ui_customization.sms_customization.off_net_fourth} ${transfer_notification?.data?.shortlink}`,
    };
  }

  useEffect(() => {
    if (!isLoading && shouldShowSmsSend()) {
      if (transfer_notification?.verb == "shortlink_generated" || fi_ui_customization?.send_on_network_notification) {
        clickSendSms(resendData.message, resendData.identifier?.value);
      }
    }
  }, [fi_ui_customization, isLoading, transfer]);

  let backPath = MAIN_PAGE_PATH;

  if (fromDemoPage) {
    backPath = NP_EVENT_DEMO;
  }

  const { renderBackButton } = ButtonBack(backPath);

  return (
    <React.Fragment>
      {transfer && (
        <main>
          <Grid container>
            <Grid style={{ ...appStyles.row, ...appStyles.bgDark }} justifyContent="center" container item>
              {renderBackButton()}
              <Typography style={appStyles.title}>Money Sent</Typography>
            </Grid>
          </Grid>
          <div style={{ ...appStyles.row, ...appStyles.bgMedium, textAlign: "center" }}>
            <Typography component="p" variant="subtitle2">
              {stddatetime(new Date(transfer.timestamp + "Z"))}
            </Typography>
            <Typography component="p" variant="body2" color="secondary.light">
              {transfer.status === "PENDING" ? (
                <>
                  <b>Pending</b> outgoing transfer
                </>
              ) : (
                <>Outgoing transfer</>
              )}
            </Typography>
          </div>
          <div style={{ ...appStyles.row, textAlign: "center" }}>
            <Typography component="p" variant="h6" color="secondary.dark">
              {transfer.recipient.value}
            </Typography>
            <div
              style={
                transfer.status === "PENDING"
                  ? {
                      ...appStyles.circle2,
                      ...appStyles.outgoingIcoBg,
                      ...appStyles.pendingIcoBg,
                      textAlign: "center",
                    }
                  : { ...appStyles.circle2, ...appStyles.outgoingIcoBg, textAlign: "center" }
              }
            >
              <img src={ico_send} alt="Outgoing transfer icon" style={appStyles.icoTransfer2} />
            </div>
            <Typography style={{ marginBottom: "10px" }} component="p" variant="h3" color="secondary.dark">
              -${transfer.amount.value}
            </Typography>
            <React.Fragment>
              <Typography component="p" variant="subtitle1" color="secondary.dark">
                {account ? account.name : ""}
              </Typography>
            </React.Fragment>
          </div>
          <div style={{ padding: "0 35px 35px 35px" }}>
            <Typography component="p" variant="subtitle2" color="secondary.light">
              Comment
            </Typography>
            <Typography component="p" variant="subtitle1" color="secondary.dark">
              <i>{transfer.note}</i>
            </Typography>
          </div>
          <div style={{ padding: "0 35px 150px 35px" }}>
            <Typography component="p" variant="subtitle2" color="secondary.light">
              Reference ID:
            </Typography>
            <Typography component="p" variant="subtitle1" color="secondary.dark">
              <i>{transfer.id}</i>
            </Typography>
          </div>
          <div style={{ position: "fixed", bottom: 0, width: "100%", textAlign: "center" }}>
            {shouldShowSmsSend() ? (
              <React.Fragment>
                <Grid container justifyContent="center" style={{ padding: "20px 10px", ...appStyles.bgMedium }}>
                  Click below to send an SMS message to the recipient if your messaging app does not open automatically.
                </Grid>
                <Grid container>
                  <Button
                    onClick={() => navigate(backPath)}
                    style={{ width: "50%", borderRadius: 0, borderRight: "1px solid white" }}
                  >
                    Return Home
                  </Button>
                  <SendSms {...resendData}>
                    <>Send Message</>
                  </SendSms>
                </Grid>
              </React.Fragment>
            ) : (
              <Button style={{ height: "60px" }} fullWidth onClick={() => navigate(backPath)}>
                Got it
              </Button>
            )}
          </div>
        </main>
      )}
    </React.Fragment>
  );
});

export default TransferSuccessful;
