import React from "react";
import { hrefRouter as bannoRouter } from "../../utils/banno";
import { MOBILE_PROVIDERS } from "src/constants";
import GlobalTheme from "src/theme";
import { Button } from "@mui/material";
import { isSMSCapable } from "../../utils/index";

export const canSendSMS = isSMSCapable();

export const clickSendSms = (messageBody: string, phoneNum: string) => {
  const mobileProvider = localStorage.getItem("mobileProvider");
  let href = `sms:${phoneNum}?body=${messageBody}`;
  if (mobileProvider === MOBILE_PROVIDERS.BANNO) {
    bannoRouter(encodeURI(href), true);
  } else if (mobileProvider === MOBILE_PROVIDERS.MEA) {
    window.location.href = href;
  } else {
    href = `sms:${phoneNum}?body=${encodeURIComponent(messageBody)}`;
    const a = document.createElement("a");
    a.href = href;
    a.click();
  }
};

const SendSms = ({
  identifier,
  children,
  disabled,
  message,
}: {
  identifier: Record<string, any>;
  children: JSX.Element;
  disabled: boolean;
  message: string;
}) => {
  const { fi_ui_customization } = GlobalTheme();

  return (
    <Button
      href="#"
      disabled={disabled}
      onClick={() => {
        clickSendSms(message, identifier?.value);
      }}
      style={
        !disabled
          ? {
              borderRadius: "0",
              flexGrow: "2",
            }
          : {
              borderRadius: "0",
              flexGrow: "2",
              backgroundColor: fi_ui_customization.button_disabled,
              color: fi_ui_customization.contrast1,
              cursor: "default",
            }
      }
    >
      <span style={{ fontSize: "16px", fontWeight: 600 }}>{children}</span>
    </Button>
  );
};

export default SendSms;
