import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Typography, Button, CircularProgress } from "@mui/material";
import { Account, TransferHistoryItem } from "src/services/generatedApi";
import ico_send from "src/assets/ico_send.svg";
import AccContext from "src/stores/AccountStore";
import TransferContext from "src/stores/TransferStore";
import NotificationContext from "src/stores/NotificationStore";
import { ROUTE_CONSTANTS } from "src/constants";
import ButtonBack from "./ButtonBack";
import { stddatetime } from "src/utils";
import SendSms from "./SendSms";
import GlobalTheme from "src/theme";
import { canSendSMS } from "./SendSms";

const { DEMO_LOGIN_PATH, HISTORY_PAGE_PATH, MAIN_PAGE_PATH } = ROUTE_CONSTANTS;

interface IURLParams {
  id?: string;
}

const OutgoingDetails = ({ appStyles }: { appStyles: any }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [transferCancelFailed, setTransferCancelFailed] = useState(false);
  const accStore = useContext(AccContext);
  const transferStore = useContext(TransferContext);
  const notificationStore = useContext(NotificationContext);
  const [account, setAccount] = useState<Account | undefined>(undefined);
  const [transfer, setTransfer] = useState<TransferHistoryItem | undefined>(undefined);
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  const { fi_ui_customization } = GlobalTheme();

  const urlParams: IURLParams = useParams();

  const statusInfo = () => {
    if (transfer && "PENDING" === transfer.status) {
      return (
        <Typography component="p" variant="body2" color="secondary.light">
          <b>Pending</b> outgoing transfer
        </Typography>
      );
    }
    if (transfer && "PENDING" !== transfer.status) {
      return (
        <Typography component="p" variant="body2" color="secondary.light">
          Outgoing transfer
        </Typography>
      );
    }
  };

  useEffect(() => {
    const loadStores = async () => {
      try {
        await accStore.loadAccounts();
        await notificationStore.loadNotifications();
        await transferStore.loadTransfers();
      } catch (error) {
        navigate(DEMO_LOGIN_PATH);
      } finally {
        const t1 = transferStore.history.find(({ id }) => id === urlParams.id);
        setTransfer(t1);
        if (t1) {
          setAccount(accStore.accounts.find(({ id }) => id === t1.account));
        }
      }
    };

    loadStores();
  }, [urlParams.id, accStore, transferStore, navigate, notificationStore]);

  const notificationData = notificationStore.notifications.find(({ transfer_id }) => transfer_id === urlParams.id)
    ?.data;

  const [resendData, setResendData] = useState({
    identifier: notificationData?.identifier,
    children: "",
    disabled: isLoading,
    message: `${fi_ui_customization.sms_customization.on_net_first} $${notificationData?.amount?.value} ${fi_ui_customization.sms_customization.on_net_second} "${notificationData?.note}". ${fi_ui_customization.sms_customization.on_net_third}`,
  });

  useEffect(() => {
    if (notificationData?.shortlink) {
      setResendData({
        ...resendData,
        message: `${fi_ui_customization.sms_customization.off_net_first} $${notificationData?.amount?.value} ${fi_ui_customization.sms_customization.off_net_second} ${notificationData?.financial_institution} ${fi_ui_customization.sms_customization.off_net_third} "${notificationData?.note}". ${fi_ui_customization.sms_customization.off_net_fourth} ${notificationData?.shortlink}`,
      });
    }
  }, [notificationData]);

  const handleTransferCancelFailure = () => {
    setIsLoading(false);
    setTransferCancelFailed(true);
  };

  const handleTransferCanceled = async () => {
    setIsLoading(true);
    setResendData({
      ...resendData,
      disabled: true,
    });
    const transferIdToCancel = transfer ? transfer.id : null;

    if (!transferIdToCancel) {
      handleTransferCancelFailure();
      return;
    }

    notificationStore.cancelingTransferId = transferIdToCancel;
    try {
      await transferStore.cancelTransfer(transferIdToCancel);
    } catch (err) {
      handleTransferCancelFailure();
    }

    const interval = setInterval(() => {
      if (
        notificationStore.notifications.find((one) => one.data.transfer_id === urlParams.id)?.data.status === "failed"
      ) {
        clearInterval(interval);
        navigate(HISTORY_PAGE_PATH);
      }
    }, 1000);
    setTimeout(() => {
      clearInterval(interval);
      setStatus("timeout");
    }, 15000);
  };

  const { renderBackButton } = ButtonBack(HISTORY_PAGE_PATH);

  return (
    <React.Fragment>
      {transfer && (
        <>
          {status !== "timeout" ? (
            <main>
              <Grid container>
                <Grid style={{ ...appStyles.row, ...appStyles.bgDark }} justifyContent="center" container item>
                  {renderBackButton()}
                  <Typography style={appStyles.title}>Transfer Details</Typography>
                </Grid>
              </Grid>
              <div style={{ ...appStyles.row, ...appStyles.bgMedium, textAlign: "center" }}>
                <Typography component="p" variant="subtitle2">
                  {stddatetime(new Date(transfer.timestamp + "Z"))}
                </Typography>
                {statusInfo()}
              </div>
              <div style={{ ...appStyles.row, textAlign: "center" }}>
                <Typography component="p" variant="h6" color="secondary.dark">
                  {transfer.recipient?.value}
                </Typography>
                <div
                  style={
                    transfer.status === "PENDING"
                      ? {
                          ...appStyles.circle2,
                          ...appStyles.outgoingIcoBg,
                          ...appStyles.pendingIcoBg,
                          textAlign: "center",
                        }
                      : { ...appStyles.circle2, ...appStyles.outgoingIcoBg, textAlign: "center" }
                  }
                >
                  <img src={ico_send} alt="Outgoing transfer icon" style={appStyles.icoTransfer2} />
                </div>
                <Typography style={{ marginBottom: "10px" }} component="p" variant="h3" color="secondary.dark">
                  -${transfer.amount?.value}
                </Typography>
                <React.Fragment>
                  <Typography component="p" variant="subtitle1" color="secondary.dark">
                    {account ? account.name : ""}
                  </Typography>
                </React.Fragment>
              </div>
              <div style={{ padding: "0 35px 35px 35px" }}>
                <Typography component="p" variant="subtitle2" color="secondary.light">
                  Comment:
                </Typography>
                <Typography component="p" variant="subtitle1" color="secondary.dark">
                  <i>{transfer.note}</i>
                </Typography>
              </div>
              <div style={{ padding: "0 35px 150px 35px" }}>
                <Typography component="p" variant="subtitle2" color="secondary.light">
                  Reference ID:
                </Typography>
                <Typography component="p" variant="subtitle1" color="secondary.dark">
                  <i>{transfer.id}</i>
                </Typography>
              </div>
              <div style={{ textAlign: "center", position: "fixed", bottom: 0, width: "100%" }}>
                {"PENDING" === transfer.status && (
                  <React.Fragment>
                    <div style={{ ...appStyles.row, ...appStyles.bgMedium, textAlign: "center" }}>
                      <Typography component="p" variant="subtitle1">
                        Recipient didn’t accept funds yet
                      </Typography>
                    </div>
                    {transferCancelFailed ? (
                      <div style={{ ...appStyles.row, ...appStyles.bgMedium, textAlign: "center" }}>
                        <Typography component="p" variant="subtitle1">
                          Transfer cancel failed. Please contact your bank for more info.
                        </Typography>
                      </div>
                    ) : (
                      <Grid container style={{ display: "flex", flexGrow: "2" }}>
                        {transfer.is_cancellable && (
                          <Button
                            style={
                              transfer.recipient.type === "phone" &&
                              notificationData &&
                              (notificationData?.shortlink || fi_ui_customization?.send_on_network_notification)
                                ? {
                                    flexGrow: "2",
                                    borderRadius: 0,
                                    height: "60px",
                                    borderRight: "1px solid white",
                                  }
                                : {
                                    flexGrow: "2",
                                    borderRadius: 0,
                                    height: "60px",
                                  }
                            }
                            onClick={handleTransferCanceled}
                            disabled={isLoading}
                          >
                            Cancel Transfer
                            {isLoading && <CircularProgress style={{ marginLeft: "10px" }} color="inherit" size={25} />}
                          </Button>
                        )}
                        {canSendSMS &&
                          transfer.recipient.type === "phone" &&
                          notificationData &&
                          (notificationData?.shortlink || fi_ui_customization?.send_on_network_notification) && (
                            <SendSms {...resendData}>
                              <>Send Message</>
                            </SendSms>
                          )}
                      </Grid>
                    )}
                  </React.Fragment>
                )}
                {"FAILED" === transfer.status && (
                  <React.Fragment>
                    <div style={{ ...appStyles.row, ...appStyles.bgMedium, textAlign: "center" }}>
                      <Typography component="p" variant="subtitle1">
                        Failed
                      </Typography>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </main>
          ) : (
            <main>
              <Grid style={{ ...appStyles.row, ...appStyles.bgDark }} justifyContent="center" container item>
                <Typography style={{ ...appStyles.title, padding: 10 }}>
                  Your transfer cancellation is still processing. Please check the status in the 'History' tab later.
                </Typography>
              </Grid>
              <div style={{ ...appStyles.row, textAlign: "center" }}>
                <Typography style={appStyles.title}>Transfer Cancellation Details:</Typography>
                <Typography component="div" variant="h6" color="secondary.dark">
                  <p>To</p>
                  <p style={{ fontWeight: 600 }}>{transfer.recipient?.value}</p>
                  <p>Amount</p>
                  <p style={{ fontWeight: 600 }}>${transfer.amount?.value}</p>
                </Typography>
              </div>
              <div style={{ padding: "0 35px 35px 35px" }}>
                <Typography component="p" variant="subtitle2" color="secondary.light">
                  Comment
                </Typography>
                <Typography component="p" variant="subtitle1" color="secondary.dark">
                  <i>{transfer.note}</i>
                </Typography>
              </div>
              <div style={{ padding: "0 35px 150px 35px" }}>
                <Typography component="p" variant="subtitle2" color="secondary.light">
                  Reference ID:
                </Typography>
                <Typography component="p" variant="subtitle1" color="secondary.dark">
                  <i>{transfer.id}</i>
                </Typography>
              </div>
              <div style={{ position: "fixed", bottom: 0, width: "100%", textAlign: "center" }}>
                <Grid container>
                  <Button
                    fullWidth
                    onClick={() => navigate(MAIN_PAGE_PATH)}
                    style={{ borderRadius: 0, height: "60px" }}
                  >
                    Return Home
                  </Button>
                </Grid>
              </div>
            </main>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default OutgoingDetails;
